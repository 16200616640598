import React, { useRef, useState, useEffect } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  List,
  IconButton,
  ListItemAvatar,
  Avatar,
  ListItem,
  ListItemText,
} from '@mui/material';
import {
  UploadFile as UploadFileIcon,
  Delete as DeleteIcon,
  AttachFile as AttachFileIcon,
} from '@mui/icons-material';
import { Box } from '@mui/system';

export const FileUploadScheduleUser = ({
  label = '',
  onChange,
  value = '',
  name = '',
  size = 'small',
  margin = 'dense',
  fullWidth = true,
  error = false,
  helperText = '',
  multiple = false,
  marinTop = 3,
  isUploadOnlyContract = false,
  valueCurrent = [], // List file current
  onChangeCurrent, // onchange file current
  limitLength = 80,
  limitLengthStart = 60,
  limitLengthEnd = 20,
  ...props
}) => {
  const ref = useRef(null);
  const [fileList, setFileList] = useState([]);

  useEffect(() => {
    if (!multiple && value?.isAvailable) {
      setFileList([[value]]);
      return;
    }
  }, [value]);

  useEffect(() => {
    if (multiple && isUploadOnlyContract) {
      setFileList(value);
      ref.current.value = '';
      return;
    }
  }, [value, isUploadOnlyContract]);

  const submitOnchange = (newValue) => {
    const submit = (value) =>
      onChange &&
      onChange({
        persist: () => {},
        target: {
          type: 'change',
          name,
          value,
        },
      });
    if (!multiple) {
      if (!newValue?.length) {
        return submit(null);
      }
      return submit(newValue[0]);
    }

    submit(newValue);
  };
  const onAddFile = (e) => {
    const file = e?.target?.files;
    if (!file?.length) {
      return;
    }
    if (!multiple) {
      setFileList([file]);
      submitOnchange(file);
    } else {
      const tmp = fileList.map((item) => item);
      const convertDataFile = Array.from(file).map((item) => tmp.push([item]));
      setFileList(tmp);
      submitOnchange(tmp);
    }
  };
  const onDeleteFile = (index) => {
    const tmpFiles = fileList.filter((fItem, fIndex) => fIndex != index);
    setFileList(tmpFiles);
    submitOnchange(tmpFiles);
    ref.current.value = '';
  };

  const handleDeleteCurrentFile = (index) => {
    const tmpFiles = valueCurrent.filter((fItem, fIndex) => fIndex != index);
    onChangeCurrent(tmpFiles);
    ref.current.value = '';
  };

  const showListFile = () => (
    <List dense={true}>
      {fileList?.map(([item], index) => {
        const fileName = item?.name || '';

        const fileNameFormat =
          fileName?.length > limitLength
            ? fileName?.slice(0, limitLengthStart) +
              ' ... ' +
              fileName?.slice(fileName?.length - limitLengthEnd, fileName?.length)
            : fileName;

        return (
          <ListItem
            key={`file-item-${index}`}
            secondaryAction={
              <IconButton edge="end" aria-label="delete" onClick={() => onDeleteFile(index)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <AttachFileIcon fontSize="small" />
            </ListItemAvatar>
            <ListItemText
              sx={item?.isAvailable && { cursor: 'pointer' }}
              onClick={() => {
                if (item?.isAvailable) {
                  window.open(`${process.env.REACT_APP_API_URL}/${item?.filePath}`, '_blank');
                }
              }}
              primary={fileNameFormat}
            />
          </ListItem>
        );
      })}
    </List>
  );

  const showListFileCurrent = () => (
    <List dense={true} sx={{ color: 'blue' }}>
      {valueCurrent?.map((item, index) => {
        const fileName =
          item?.length > limitLength
            ? item?.slice(0, limitLengthStart) +
              ' ... ' +
              item?.slice(item?.length - limitLengthEnd, item?.length)
            : item;
        return (
          <ListItem
            key={`file-item-${index}`}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                onClick={() => handleDeleteCurrentFile(index)}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            }
          >
            <ListItemAvatar>
              <AttachFileIcon fontSize="small" />
            </ListItemAvatar>
            <ListItemText
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                window.open(`${process.env.REACT_APP_API_URL}/${item}`, '_blank');
              }}
              primary={fileName}
            />
          </ListItem>
        );
      })}
    </List>
  );

  return (
    <>
      <FormControl
        {...{
          size,
          fullWidth,
          margin,
        }}
        sx={{ mt: marinTop }}
      >
        {/* <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel> */}
        <Box style={{ marginTop: '20px', display: 'flex', justifyContent: 'space-between' }}>
          <Box>
            <b>Tiệp đính kèm:</b>
          </Box>

          <Button
            variant="link"
            component="label"
            sx={{ width: 'fit-content', textTransform: 'none', fontWeight: '400' }}
          >
            <AttachFileIcon />
            <span
              style={{
                color: 'blue',
                fontSize: '15px',
                marginLeft: '5px',
              }}
            >
              Thêm file đính kèm
            </span>
            <input
              type="file"
              hidden
              ref={ref}
              onChange={(e) => onAddFile(e)}
              multiple={multiple}
            />
          </Button>
        </Box>

        {fileList?.length ? <Box>{showListFile()}</Box> : null}
        {valueCurrent?.length ? <Box>{showListFileCurrent()}</Box> : null}
      </FormControl>
      {error && (
        <div style={{ color: '#d32f2f', fontSize: '0.75rem', marginTop: '3px' }}>{helperText}</div>
      )}
    </>
  );
};
