import React, { useState, useEffect, useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { HandleFormDetail, TextInput, DateTimePicker, ComboBox } from 'components';
import { Box, Button, Grid } from '@mui/material';
import { Formik } from 'formik';
import ApiServices from 'services';
import { RESPONSE_STATUSES } from 'commons/constants';
import moment from 'moment';

const FORM_WIDTH = '600px';
export const FormDetail = ({ selected, setSelected, reloadData, ...props }) => {
  // init List
  const [userAssignerList, setUserAssignerList] = useState([]);
  const [userManagerList, setUserManagerList] = useState([]);
  const [clientCompanyList, setClientCompanyList] = useState([]);
  const [reviewerList, setReviewerList] = useState([]);

  const [arrAssigner, setArrAssigner] = useState([]);
  const [arrManager, setArrManager] = useState([]);
  const [arrParentClientCompanyId, setArrParentClientCompanyId] = useState([]);
  const [reviewer, setReviewer] = useState(null);

  const checkSubmit = useMemo(() => {
    return !arrAssigner?.length || !reviewer;
  }, [arrAssigner, reviewer]);

  const getUsers = async () => {
    try {
      const res = await ApiServices.getUsers();
      if (res?.data) {
        setUserManagerList(res?.data || []);
        setUserAssignerList(res?.data || []);
        setReviewerList(res?.data || []);
      }
    } catch (error) {
      console.log('getUsers Failed:::', error);
    }
  };

  const getClientCompanies = async () => {
    try {
      const res = await ApiServices.getClientCompanies();
      if (res?.data) {
        setClientCompanyList(res?.data || []);
      }
    } catch (error) {
      console.log('getClientCompanies Failed:::', error);
    }
  };

  useEffect(() => {
    getUsers();
    getClientCompanies();
  }, []);

  useEffect(() => {
    setArrAssigner([]);
    setArrManager([]);
    setArrParentClientCompanyId([]);
    setReviewer(null);
  }, [selected]);

  const handleChangeArrAssigner = useCallback(
    (_, newValue) => {
      setArrAssigner(newValue || null);
    },
    [arrAssigner],
  );

  const handleChangeReviewer = useCallback(
    (_, newValue) => {
      setReviewer(newValue || null);
    },
    [reviewer],
  );

  const handleChangeArrManager = useCallback(
    (_, newValue) => {
      setArrManager(newValue || null);
    },
    [arrManager],
  );

  const handleChangeArrParentClientCompanyId = useCallback(
    (_, newValue) => {
      setArrParentClientCompanyId(newValue || null);
    },
    [arrParentClientCompanyId],
  );

  const createFn = async (values, { setSubmitting }) => {
    const arrAssignerCode = arrAssigner?.map((item) => item?.code);
    const arrManagerCode = arrManager?.map((item) => item?.code);
    const arrParentClientCompanyIdSend = arrParentClientCompanyId?.map((item) => item?.id);

    const strDateStart = moment(values?.beginWork).format('YYYY-MM-DD HH:mm');
    const strDateEnd = moment(values?.endWork).format('YYYY-MM-DD HH:mm');
    const dateStart = moment(strDateStart);
    const dateEnd = moment(strDateEnd);
    const compare = dateEnd.isBefore(dateStart);

    if (compare) {
      window.UIMessage?.error('Thời gian kết thúc phải lớn hơn Thời gian bắt đầu');
      setSubmitting(false);
      return;
    }

    const dataSend = {
      code: values?.code,
      name: values?.name,
      beginWork: values?.beginWork,
      endWork: values?.endWork,
      note: values?.note,
      workObjective: values?.workObjective,
      arrManager: arrManagerCode || [],
      arrAssigner: arrAssignerCode || [],
      arrParentClientCompanyId: arrParentClientCompanyIdSend || [],
      reviewer: reviewer?.code || '',
    };

    setSubmitting(true);
    return ApiServices.createScheduleUser(dataSend)
      .then((res) => {
        console.log({ res });
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Thêm mới thành công');
          setSelected(null);
          reloadData();
        }
      })
      .catch((err) => {
        window.UIMessage?.error('Thêm mới thất bại !!!');
        console.log(err);
      })
      .finally(() => setSubmitting(false));
  };

  const updateFn = async (values, { setSubmitting }) => {};

  return (
    <>
      <HandleFormDetail
        {...{ selected, setSelected, width: FORM_WIDTH }}
        showTitleTab={selected?.isNew ? 'Tạo mới' : `Mã: ${selected?.code}`}
      >
        <Formik
          initialValues={{
            name: '',
            beginWork: '',
            endWork: '',
            note: '',
            workObjective: '',
            // workUpdateResult: '',
            ...selected,
          }}
          validationSchema={Yup.object().shape({
            name: Yup.string().required('Vui lòng nhập tên công việc'),
            beginWork: Yup.string().required('Vui lòng chọn ngày bắt đầu'),
            endWork: Yup.string().required('Vui lòng chọn ngày kết thúc'),
            note: Yup.string().required('Vui lòng nhập nội dung công việc'),
            workObjective: Yup.string().required('Vui lòng nhập mục tiêu công việc'),
          })}
          onSubmit={(values, { setSubmitting }) => {
            if (selected?.isNew) {
              createFn(values, { setSubmitting });
            } else {
              updateFn(values, { setSubmitting });
            }
          }}
        >
          {({ errors, handleBlur, handleChange, handleSubmit, isSubmitting, touched, values }) => (
            <form onSubmit={handleSubmit} style={{ width: '100%' }}>
              <Box>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextInput
                      required
                      error={Boolean(touched.name && errors.name)}
                      helperText={touched.name && errors.name}
                      label="Tên công việc"
                      name="name"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.name}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {/* <TextInput
                      required
                      error={Boolean(touched.beginWork && errors.beginWork)}
                      helperText={touched.beginWork && errors.beginWork}
                      label="Thời gian bắt đầu"
                      name="beginWork"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.beginWork}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    /> */}

                    <DateTimePicker
                      onChange={handleChange}
                      value={values.beginWork}
                      error={Boolean(touched.beginWork && errors.beginWork)}
                      helperText={touched.beginWork && errors.beginWork}
                      label="Thời gian bắt đầu"
                      name="beginWork"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {/* <TextInput
                      required
                      error={Boolean(touched.endWork && errors.endWork)}
                      helperText={touched.endWork && errors.endWork}
                      label="Thời gian kết thúc"
                      name="endWork"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.endWork}
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    /> */}
                    <DateTimePicker
                      onChange={handleChange}
                      value={values.endWork}
                      error={Boolean(touched.endWork && errors.endWork)}
                      helperText={touched.endWork && errors.endWork}
                      label="Thời gian kết thúc"
                      name="endWork"
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={12}>
                    <TextInput
                      required
                      multiline={true}
                      rows={4}
                      error={Boolean(touched.note && errors.note)}
                      helperText={touched.note && errors.note}
                      label="Nội dung công việc"
                      name="note"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.note}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <TextInput
                      error={Boolean(touched.workObjective && errors.workObjective)}
                      helperText={touched.workObjective && errors.workObjective}
                      label="Mục tiêu công việc"
                      name="workObjective"
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.workObjective}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <ComboBox
                      // error={!arrParentClientCompanyId?.length}
                      // helperText={!arrParentClientCompanyId?.length && 'Vui lòng chọn Khách hàng'}
                      disableCloseOnSelect
                      options={clientCompanyList}
                      label="Khách hàng"
                      sx={{ width: '100%', marginTop: '8px' }}
                      keyField="code"
                      value={arrParentClientCompanyId}
                      onChange={handleChangeArrParentClientCompanyId}
                      multiple
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <ComboBox
                      // error={!arrManager?.length}
                      // helperText={!arrManager?.length && 'Vui lòng chọn Người theo dõi'}
                      disableCloseOnSelect
                      options={userManagerList}
                      label="Người theo dõi"
                      sx={{ width: '100%', marginTop: '8px' }}
                      keyField="name"
                      value={arrManager}
                      onChange={handleChangeArrManager}
                      multiple
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <ComboBox
                      error={!reviewer}
                      helperText={!reviewer && 'Vui lòng chọn Người đánh giá'}
                      // disableCloseOnSelect
                      options={reviewerList}
                      label="Người đánh giá"
                      sx={{ width: '100%', marginTop: '8px' }}
                      keyField="name"
                      value={reviewer}
                      onChange={handleChangeReviewer}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <ComboBox
                      error={!arrAssigner?.length}
                      helperText={!arrAssigner?.length && 'Vui lòng chọn Người thực hiện'}
                      disableCloseOnSelect
                      options={userAssignerList}
                      label="Người thực hiện"
                      sx={{ width: '100%', marginTop: '8px' }}
                      keyField="name"
                      value={arrAssigner}
                      onChange={handleChangeArrAssigner}
                      multiple
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box sx={{ py: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button
                  sx={{
                    padding: '10px 30px',
                    textTransform: 'none',
                  }}
                  color="primary"
                  disabled={isSubmitting || checkSubmit}
                  size="small"
                  type="submit"
                  variant="contained"
                >
                  {selected?.isNew ? 'Thêm mới' : 'Cập nhật'}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </HandleFormDetail>
    </>
  );
};
