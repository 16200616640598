import * as React from 'react';
import { Drawer, Box, Tab, Stack, Grid, Paper } from '@mui/material';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { HeaderInformationDetails } from './header-information-details';
import { LeftInformation } from './left-information';

// Tab
import { TabInformationDetail } from './tabs/tab-information-detail';
import { TabCallManagement } from './tabs/tab-call-management';
import { TabEmailManagement } from './tabs/tab-email-management';
import { TabScheduleManagement } from './tabs/tab-schedule-management';
import { TabContract } from './tabs/tab-contract';
import { TabTaskJob } from './tabs/tab-task-job';

const DrawerInformationDetails = ({
  isOpenDrawer,
  setIsOpenDrawer,
  clientCareHistoryItem,
  clientInformationDetail,
  getClientCareHistoryDetails,
}) => {
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Drawer anchor="right" open={isOpenDrawer} onClose={() => setIsOpenDrawer(false)}>
      <Box sx={{ width: 1200, height: '100vh', display: 'flex' }}>
        <Box style={{ background: '#EFFBF7', width: '300px', height: 'auto' }}>
          <LeftInformation
            clientCareHistoryItem={clientCareHistoryItem}
            clientInformationDetail={clientInformationDetail}
            getClientCareHistoryDetails={getClientCareHistoryDetails}
          />
        </Box>
        <Box style={{ background: '#FFF', width: '900px', height: 'auto' }}>
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="lab API tabs example">
                  <Tab
                    sx={{ marginLeft: 4, textTransform: 'none' }}
                    label="Người liên hệ"
                    value="1"
                  />
                  <Tab sx={{ marginLeft: 4, textTransform: 'none' }} label="Cuộc gọi" value="2" />
                  <Tab sx={{ marginLeft: 4, textTransform: 'none' }} label="Mails" value="3" />
                  <Tab sx={{ marginLeft: 4, textTransform: 'none' }} label="Lịch hẹn" value="4" />
                  <Tab sx={{ marginLeft: 4, textTransform: 'none' }} label="Hợp đồng" value="5" />
                  <Tab sx={{ marginLeft: 4, textTransform: 'none' }} label="Công việc" value="6" />
                </TabList>
              </Box>

              <TabPanel value="1">
                <TabInformationDetail
                  clientCareHistoryItem={clientCareHistoryItem}
                  clientInformationDetail={clientInformationDetail}
                  getClientCareHistoryDetails={getClientCareHistoryDetails}
                  value={value}
                  setValue={setValue}
                />
              </TabPanel>
              <TabPanel value="2">
                <TabCallManagement
                  clientCareHistoryItem={clientCareHistoryItem}
                  value={value}
                  setValue={setValue}
                />
              </TabPanel>
              <TabPanel value="3">
                <TabEmailManagement
                  clientCareHistoryItem={clientCareHistoryItem}
                  value={value}
                  setValue={setValue}
                />
              </TabPanel>
              <TabPanel value="4">
                <TabScheduleManagement
                  clientCareHistoryItem={clientCareHistoryItem}
                  value={value}
                  setValue={setValue}
                />
              </TabPanel>
              <TabPanel value="5">
                <TabContract
                  clientCareHistoryItem={clientCareHistoryItem}
                  value={value}
                  setValue={setValue}
                />
              </TabPanel>
              <TabPanel value="6">
                <TabTaskJob
                  clientCareHistoryItem={clientCareHistoryItem}
                  value={value}
                  setValue={setValue}
                />
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Box>
    </Drawer>
  );
};

export { DrawerInformationDetails };
