import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Box, Grid } from '@mui/material';
import { TextInput, ComboBox, DateTimePicker } from 'components';

import { BtnCreateUpdate } from '../styles';
import ApiServices from 'services';
import { RESPONSE_STATUSES } from 'commons/constants';
import Storage from 'storage';
import moment from 'moment';
import { set } from 'lodash';

const CreateSubJob = ({ dataMainJob, onCloseCreateSubJob, reloadData }) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [userManagerList, setUserManagerList] = useState([]);
  const [clientCompanyList, setClientCompanyList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [reviewerList, setReviewerList] = useState([]);

  const [name, setName] = useState('');
  const [beginWork, setBeginWork] = useState(null);
  const [endWork, setEndWork] = useState(null);
  // Mục tiêu công việc
  const [workObjective, setWorkObjective] = useState('');
  // Noi dung công việc
  const [note, setNote] = useState('');
  // Người theo dõi
  const [arrManager, setArrManager] = useState([]);

  // Khách hàng
  const [clientCompanyId, setClientCompanyId] = useState(null);
  const [clientCompanyName, setClientCompanyName] = useState('');
  const [serviceId, setServiceId] = useState(null);

  // Người dánh giá
  const [reviewer, setReviewer] = useState(null);

  const checkSubmit = useMemo(() => {
    return !reviewer;
  }, [reviewer]);

  const getClientCompanies = async (dataMainJobRow) => {
    const scheduleMainJobId = dataMainJobRow?.id || '';
    if (!scheduleMainJobId) return;

    try {
      const res = await ApiServices.getClientComp_ScheUserMain(scheduleMainJobId);
      if (res?.data) {
        setClientCompanyList(res?.data || []);
      }
    } catch (error) {
      console.log('getClientCompanies Failed:::', error);
    }
  };

  const getUsers = async () => {
    try {
      const res = await ApiServices.getUsers();
      if (res?.data) {
        setUserManagerList(res?.data || []);
        setReviewerList(res?.data || []);

        const reviewerList = res?.data?.find((item) => item?.code === Storage?.user?.Code);
        setReviewer(reviewerList || null);
      }
    } catch (error) {
      console.log('getUsers Failed:::', error);
    }
  };

  useEffect(() => {
    getUsers();
    getClientCompanies(dataMainJob);
  }, []);

  const handleChangeName = useCallback(
    (e) => {
      setName(e.target.value);
    },
    [name],
  );

  // Close modal
  const handleCloseModal = () => {
    onCloseCreateSubJob();
  };

  const handleSubmit = async () => {
    const arrAssignerCode = Storage?.user?.Code || '';
    if (!arrAssignerCode) return;

    const arrManagerCode = arrManager?.map((item) => item?.code);
    const companyId = clientCompanyId?.id || '';
    const serId = serviceId?.id || '';

    const strDateStart = moment(beginWork).format('YYYY-MM-DD HH:mm');
    const strDateEnd = moment(endWork).format('YYYY-MM-DD HH:mm');
    const dateStart = moment(strDateStart);
    const dateEnd = moment(strDateEnd);
    const compare = dateEnd.isBefore(dateStart);

    if (compare) {
      window.UIMessage?.error('Ngày kết thúc phải lớn hơn ngày bắt đầu');
      setLoadingSubmit(false);
      return;
    }

    const dataSend = {
      name: name,
      beginWork: beginWork,
      endWork: endWork,
      note: note,
      workObjective: workObjective,
      arrManager: arrManagerCode || [],
      arrAssigner: [arrAssignerCode],
      clientCompanyId: companyId || '',
      clientCompanyName,
      serviceId: serId || '',
      // Thêm Công việc phụ Level phải = 1 và ParentScheduleUserCode không được trống
      level: 1,
      parentScheduleUserCode: dataMainJob?.code,
      reviewer: reviewer?.code || '',
    };

    setLoadingSubmit(true);
    return ApiServices.createSubToMainJob(dataSend)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Thêm mới thành công');
          reloadData();
        }
      })
      .catch((err) => {
        // window.UIMessage?.error(`Thêm mới thất bại::: ${err?.message}  !!!`);

        console.log(err);
      })
      .finally(() => setLoadingSubmit(false));
  };

  const handleChangeBeginWork = useCallback(
    (e) => {
      setBeginWork(e.target.value);
    },
    [beginWork],
  );

  const handleChangeEndWork = useCallback(
    (e) => {
      setEndWork(e.target.value);
    },
    [endWork],
  );
  const handleChangeWorkObjective = useCallback(
    (e) => {
      setWorkObjective(e.target.value);
    },
    [workObjective],
  );

  const handleChangeNote = useCallback(
    (e) => {
      setNote(e.target.value);
    },
    [note],
  );

  const handleChangeArrManager = useCallback(
    (_, newValue) => {
      setArrManager(newValue || null);
    },
    [arrManager],
  );

  const handleChangeReviewer = useCallback(
    (_, newValue) => {
      setReviewer(newValue || null);
    },
    [reviewer],
  );

  const handleChangeCustomer = useCallback(
    (_, newValue) => {
      setServiceId(null);

      setClientCompanyId(newValue || null);
      if (newValue) {
        setClientCompanyName(newValue?.name || newValue?.code);
        // Service List
        if (newValue?.arrService?.length) {
          setServiceList(newValue?.arrService);
        } else {
          setServiceList([]);
        }
      } else {
        setClientCompanyName('');
        setServiceList([]);
      }
    },
    [clientCompanyId],
  );

  const handleChangeService = useCallback(
    (_, newValue) => {
      setServiceId(newValue || null);
    },
    [serviceId],
  );

  return (
    <Box>
      <Grid container spacing={1} style={{ marginTop: '4px' }}>
        <Grid item xs={12} sm={12} md={12}>
          <TextInput label="Tên công việc chính" name="name" value={dataMainJob?.name || ''} />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextInput
            required
            error={!name}
            helperText={!name ? 'Tên công việc không được để trống' : ''}
            label="Tên công việc"
            name="name"
            onChange={handleChangeName}
            value={name}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          {/* <TextInput
            required
            error={!beginWork}
            helperText={!beginWork ? 'Thời gian bắt đầu không được để trống' : ''}
            label="Thời gian bắt đầu"
            name="beginWork"
            onChange={handleChangeBeginWork}
            value={beginWork}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          /> */}

          <DateTimePicker
            error={!beginWork}
            helperText={!beginWork ? 'Thời gian bắt đầu không được để trống' : ''}
            onChange={handleChangeBeginWork}
            value={beginWork}
            label="Thời gian bắt đầu"
            name="beginWork"
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          {/* <TextInput
            required
            error={!endWork}
            helperText={!endWork ? 'Thời gian kết thúc không được để trống' : ''}
            label="Thời gian kết thúc"
            name="endWork"
            onChange={handleChangeEndWork}
            value={endWork}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          /> */}

          <DateTimePicker
            error={!endWork}
            helperText={!endWork ? 'Thời gian kết thúc không được để trống' : ''}
            label="Thời gian kết thúc"
            name="endWork"
            onChange={handleChangeEndWork}
            value={endWork}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <ComboBox
            options={clientCompanyList}
            label="Mã khách hàng"
            sx={{ width: '100%', marginTop: '8px' }}
            keyField="code"
            value={clientCompanyId}
            onChange={handleChangeCustomer}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <ComboBox
            options={serviceList}
            label="Dịch vụ"
            sx={{ width: '100%', marginTop: '8px' }}
            keyField="name"
            value={serviceId}
            onChange={handleChangeService}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextInput
            error={!workObjective}
            helperText={!workObjective ? 'Mục tiêu công việc không được để trống' : ''}
            label="Mục tiêu công việc"
            name="workObjective"
            onChange={handleChangeWorkObjective}
            value={workObjective}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextInput
            required
            multiline={true}
            rows={4}
            error={!note}
            helperText={!note ? 'Nội dung công việc không được để trống' : ''}
            label="Nội dung công việc"
            name="note"
            onChange={handleChangeNote}
            value={note}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <ComboBox
            disableCloseOnSelect
            options={userManagerList}
            label="Người theo dõi"
            sx={{ width: '100%', marginTop: '12px' }}
            keyField="name"
            value={arrManager}
            onChange={handleChangeArrManager}
            multiple
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <ComboBox
            error={!reviewer}
            helperText={!reviewer && 'Vui lòng chọn Người đánh giá'}
            // disableCloseOnSelect
            options={reviewerList}
            label="Người đánh giá"
            sx={{ width: '100%', marginTop: '12px', mảginBottom: '12px' }}
            keyField="name"
            value={reviewer}
            onChange={handleChangeReviewer}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextInput
            label="Người thực hiện"
            name="name"
            value={Storage?.user?.Name || Storage?.user?.Code || ''}
          />
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', py: 2 }}>
        <Box sx={{ marginLeft: 'auto' }}>
          <BtnCreateUpdate color="error" variant="contained" onClick={handleCloseModal}>
            Hủy
          </BtnCreateUpdate>
          <BtnCreateUpdate
            color="primary"
            loading={loadingSubmit}
            disabled={checkSubmit}
            variant="contained"
            onClick={handleSubmit}
          >
            Lưu
          </BtnCreateUpdate>
        </Box>
      </Box>
    </Box>
  );
};

export { CreateSubJob };
