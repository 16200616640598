import React, { useState, useEffect, useCallback } from 'react';
import {
  Box,
  Grid,
  Button,
  Checkbox,
  IconButton,
  Avatar,
  AvatarGroup,
  Tooltip,
  Stack,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  BsBoxArrowInUpRight,
  BsArrowDownCircle,
  BsPlusCircle,
  BsFilter,
  BsFillStickiesFill,
} from 'react-icons/bs';
import moment from 'moment';
import Utils from 'commons/utils';
import {
  Table,
  SearchInputColumnNew,
  SelectionSearchColumn,
  DatePicker,
  ModalFormDetail,
  DatePickerSearchColumn,
} from 'components';
import DeleteIcon from '@mui/icons-material/Delete';
import ApiServices from 'services';
import { RenderTaskJobStatus } from 'components/custom-status';
import { RESPONSE_STATUSES, TASK_JOB_STATUS_OPTIONS } from 'commons/constants';
import { debounce } from 'lodash';
import { ContainerHeader, ButtonAdd, ButtonImport, ButtonExport } from './styles';
import { CreateSubJob } from './create-sub-job';
import { UpdateSubJob } from './update-sub-job';

const START_DATE = moment().startOf('month').format();
const END_DATE = moment().endOf('date').format();

const DEFAULT_FILTER = {
  pageNumber: 1,
  pageSize: 10,
  fromDate: START_DATE,
  toDate: END_DATE,
};

const TabTaskJob = ({ clientCareHistoryItem }) => {
  console.log({ taskJob: clientCareHistoryItem });
  const [subJobList, setSubJobList] = useState([]);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [filter, setFilter] = useState(DEFAULT_FILTER);
  const [searchStatus, setSearchStatus] = useState('all');
  const [searchBeginWork, setSearchBeginWork] = useState(null);
  const [searchEndWork, setSearchEndWork] = useState(null);

  // paging
  const [totalRecords, setTotalRecords] = useState(0);

  // search Date
  const [startDate, setStartDate] = useState(START_DATE);
  const [endDate, setEndDate] = useState(END_DATE);

  // Sub job
  const [selectedCreateSubJob, setSelectedCreateSubJob] = useState(null);
  const [selectedUpdateSubJob, setSelectedUpdateSubJob] = useState(null);

  const initData = async (query, clientCareHistoryId) => {
    const dataSend = {
      ...query,
      fromDate: query?.fromDate ? Utils.convertToDateFormat(query?.fromDate) : null,
      toDate: query?.toDate ? Utils.convertToDateFormat(query?.toDate) : null,
    };

    try {
      const res = await ApiServices.getSubJobOnClientCareHistory(clientCareHistoryId, dataSend);
      if (res?.data?.data) {
        if (totalRecords !== res?.data?.totalRecords) {
          setTotalRecords(res?.data?.totalRecords);
        }
        setSubJobList(res?.data?.data);
      }
    } catch (error) {
      console.log('get data init failed:::', error);
    }
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, clientCareHistoryId) => {
      initData(newFilter, clientCareHistoryId);
    }, 500),
    [],
  );
  useEffect(() => {
    timeoutGetData(filter, clientCareHistoryItem?.id);
  }, [filter, clientCareHistoryItem]);

  const reloadData = () => {
    initData(filter, clientCareHistoryItem?.id);
  };

  const handleSearchColumn = (event) => {
    setFilter((prev) => {
      const searchArray = { ...prev.searchArray };
      const { value, name } = event.target;
      if (!value) {
        delete searchArray[name];
        return { ...prev, pageNumber: 1, searchArray };
      }
      searchArray[name] = value;
      return { ...prev, pageNumber: 1, searchArray };
    });
  };

  const handleSearchSelect = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    setSearchStatus(value);

    if (value === 'all') value = '';

    setFilter((prev) => {
      const searchArray = { ...prev.searchArray };
      if (!value && value !== 0) {
        delete searchArray[name];
        return { ...prev, pageNumber: 1, searchArray };
      }
      searchArray[name] = value;
      return { ...prev, pageNumber: 1, searchArray };
    });
  };
  const handleChangeSearchBeginWork = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilter((prev) => {
      const searchArray = { ...prev.searchArray };
      if (!value && value !== 0) {
        delete searchArray[name];
        return { ...prev, pageNumber: 1, searchArray };
      }
      searchArray[name] = value;
      return { ...prev, pageNumber: 1, searchArray };
    });
  };
  const handleChangeSearchEndWork = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilter((prev) => {
      const searchArray = { ...prev.searchArray };
      if (!value && value !== 0) {
        delete searchArray[name];
        return { ...prev, pageNumber: 1, searchArray };
      }
      searchArray[name] = value;
      return { ...prev, pageNumber: 1, searchArray };
    });
  };

  const reloadDataAfterDeleteSubJob = () => {
    initData(clientCareHistoryItem?.id);
  };
  // ===================== CREATE SUB JOB =====================
  // Close modal Create SubJob
  const handleCloseModalCreateSubJob = () => {
    setSelectedCreateSubJob(null);
  };
  const handleSelectedCreateSubJob = () => {
    setSelectedCreateSubJob(true);
  };
  // ===================== Update SUB JOB =====================
  // Close modal Update SubJob
  const handleCloseModalUpdateSubJob = () => {
    setSelectedUpdateSubJob(null);
  };
  const validateValue = (row) => {
    const dataSelect = row;
    dataSelect.beginWork = Utils.checkIsDate(row.beginWork)
      ? Utils.convertToDateTimeFormat(row.beginWork)
      : '';
    dataSelect.endWork = Utils.checkIsDate(row.endWork)
      ? Utils.convertToDateTimeFormat(row.endWork)
      : '';
    return dataSelect;
  };
  const handleSelectedUpdateSubJob = (rowData) => {
    console.log({ rowData });
    const dataSelect = validateValue(rowData);
    setSelectedUpdateSubJob(dataSelect);
  };
  // ===================== DELETE SUB JOB =====================
  const handleDeleteSubJob = async (row) => {
    if (!row?.id || loadingDelete) return;
    setLoadingDelete(true);
    return ApiServices.deleteScheduleUser(row?.id)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Xóa thành công');
          reloadDataAfterDeleteSubJob();
        }
      })
      .catch((err) => {
        // window.UIMessage?.error('Xóa thất bại !!!');
        console.warn('deleteClientComplaint', err);
      })
      .finally(() => setLoadingDelete(false));
  };

  const columns = [
    {
      id: 'code',
      numeric: true,
      disablePadding: false,
      label: <SearchInputColumnNew title="Mã CV con" name="code" onChange={handleSearchColumn} />,
      align: 'center',
      width: '100px',
      render: (row) => {
        return (
          <Button
            sx={{
              textTransform: 'none',
            }}
            variant="text"
            onClick={() => handleSelectedUpdateSubJob(row)}
          >
            {row?.code}
          </Button>
        );
      },
    },
    {
      id: 'parentScheduleUserName',
      numeric: true,
      disablePadding: false,
      label: (
        <SearchInputColumnNew
          title="Tên CV chính"
          name="parentScheduleUserName"
          onChange={handleSearchColumn}
        />
      ),
      align: 'center',
      width: '120px',
    },
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: <SearchInputColumnNew title="Tên CV con" name="name" onChange={handleSearchColumn} />,
      align: 'center',
      width: '120px',
    },
    {
      id: 'workObjective',
      numeric: true,
      disablePadding: false,
      label: (
        <SearchInputColumnNew
          title="Mục tiêu CV"
          name="workObjective"
          onChange={handleSearchColumn}
        />
      ),
      align: 'center',
      width: '100px',
    },
    {
      id: 'beginWork',
      numeric: true,
      disablePadding: false,
      label: (
        <>
          <b>Ngày bắt đầu</b>
          <DatePickerSearchColumn
            sx={{
              marginBottom: '10px',
              padding: 0,
            }}
            width={'140px'}
            marginTop="0"
            size="small"
            onChange={handleChangeSearchBeginWork}
            value={searchBeginWork}
            error={true}
            label=""
            name="beginWork"
          />
        </>
      ),
      align: 'center',
      width: '80px',
      render: (row) => Utils.formatDateFn(row?.beginWork),
    },
    {
      id: 'endWork',
      numeric: true,
      disablePadding: false,
      label: (
        <>
          <b>Ngày kết thúc</b>
          <DatePickerSearchColumn
            sx={{
              marginBottom: '10px',
              padding: 0,
            }}
            width={'140px'}
            marginTop="0"
            size="small"
            onChange={handleChangeSearchEndWork}
            value={searchEndWork}
            error={true}
            label=""
            name="endWork"
          />
        </>
      ),
      align: 'center',
      width: '80px',
      render: (row) => Utils.formatDateFn(row?.endWork),
    },
    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: (
        <>
          <b>Trạng thái</b>
          <SelectionSearchColumn
            size="small"
            label=""
            name="status"
            dataArrObject={TASK_JOB_STATUS_OPTIONS}
            value={searchStatus}
            onChange={handleSearchSelect}
            displayNone={false}
          />
        </>
      ),

      align: 'center',
      width: '90px',
      render: (row) => <RenderTaskJobStatus status={row?.status} />,
    },
    {
      id: 'note',
      numeric: true,
      disablePadding: false,
      label: '#',
      align: 'center',
      fixed: 'right',
      width: '60px',
      render: (row) => (
        <IconButton color="error" size="small" onClick={() => handleDeleteSubJob(row)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  const handleChangeStartDate = useCallback(
    (event) => {
      setStartDate(event.target.value);
    },
    [startDate],
  );
  const handleChangeEndDate = useCallback(
    (event) => {
      setEndDate(event.target.value);
    },
    [endDate],
  );

  const handleSearchDate = (startDateSearch, endDateSearch) => {
    if (!startDateSearch || !endDateSearch) return;
    setFilter((prev) => ({ ...prev, fromDate: startDateSearch, toDate: endDateSearch }));
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 10px 10px 10px',
        }}
      >
        <ContainerHeader>
          <Stack spacing={2} direction="row">
            <Box sx={{ width: '160px' }}>
              <DatePicker
                sx={{
                  marginBottom: '10px',
                  padding: 0,
                }}
                width={100}
                marginTop="0"
                size="small"
                onChange={handleChangeStartDate}
                value={startDate}
                error={true}
                label="Từ ngày"
              />
            </Box>
            <Box sx={{ width: '160px' }}>
              <DatePicker
                sx={{
                  marginBottom: '10px',
                  padding: 0,
                }}
                width={100}
                marginTop="0"
                size="small"
                onChange={handleChangeEndDate}
                value={endDate}
                error={true}
                label="Đến ngày"
              />
            </Box>

            <IconButton
              aria-label="filter"
              sx={{ color: 'blue' }}
              onClick={() => handleSearchDate(startDate, endDate)}
            >
              <SearchIcon />
            </IconButton>
          </Stack>
        </ContainerHeader>
        <ButtonAdd
          variant="contained"
          startIcon={<BsPlusCircle />}
          onClick={handleSelectedCreateSubJob}
        >
          Thêm mới
        </ButtonAdd>
      </Box>
      <Table
        rows={subJobList || []}
        headCells={columns}
        totalRecords={totalRecords}
        onPageChange={(paging) => {
          setFilter((prev) => ({ ...prev, ...paging }));
        }}
        isHeader={false}
        // onClickDetail={handleClickDetail}
      />
      <ModalFormDetail
        maxWidth="sm"
        title="Thêm mới công việc con"
        open={selectedCreateSubJob}
        onCloseModal={handleCloseModalCreateSubJob}
      >
        <CreateSubJob
          clientCareHistoryItem={clientCareHistoryItem}
          onCloseCreateSubJob={handleCloseModalCreateSubJob}
          reloadData={reloadData}
        />
      </ModalFormDetail>
      <ModalFormDetail
        maxWidth="sm"
        title="Cập nhật công việc con"
        open={selectedUpdateSubJob}
        onCloseModal={handleCloseModalUpdateSubJob}
      >
        <UpdateSubJob
          clientCareHistoryItem={clientCareHistoryItem}
          dataSubJob={selectedUpdateSubJob}
          onCloseUpdateSubJob={handleCloseModalUpdateSubJob}
          reloadData={reloadData}
        />
      </ModalFormDetail>
    </>
  );
};

export { TabTaskJob };
