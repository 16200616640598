import { TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import { pad } from 'lodash';

export const TextInputSearchColumn = ({
  value,
  name,
  onBlur,
  onChange,
  error,
  helperText,
  label,
  type = 'text',
  size = 'small',
  margin = 'dense',
  fullWidth = true,
  variant = 'outlined',
  multiline = false,
  maxRows = 5,
  InputLabelProps,
  disabled = false,
  required,
  rows = 1,
  readOnly = false,
  sx,
  ...props
}) => {
  return (
    <TextFieldCustom
      {...{
        value,
        name,
        error,
        helperText,
        label: required ? label + ' (*)' : label,
        onChange,
        onBlur,
        fullWidth,
        type,
        size,
        variant,
        margin,
        multiline,
        // maxRows,
        readOnly,
        InputLabelProps,
        disabled,
        rows,
        sx,

        // required,
      }}
    />
  );
};

export const TextFieldCustom = styled(TextField)(({ theme }) => ({
  marginTop: '1px',

  '.MuiInputBase-input': {
    padding: '7px 8px',
    height: '15px',
    fontSize: '13px',
  },
}));
