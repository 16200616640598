import React, { useState, useEffect, useCallback } from 'react';
import { Box, Grid } from '@mui/material';
import { TextInput, Selection, ComboBox, FileUploadScheduleUser, DateTimePicker } from 'components';
import moment from 'moment';
import { BtnCreateUpdate } from '../styles';
import ApiServices from 'services';
import { RESPONSE_STATUSES } from 'commons/constants';
import Storage from 'storage';

const STATUS_OPTIONS = {
  0: [
    { value: '0', content: 'Kế hoạch' },
    { value: '1', content: 'Đang làm' },
  ],
  1: [
    { value: '0', content: 'Kế hoạch' },
    { value: '1', content: 'Đang làm' },
    { value: '2', content: 'Chờ đánh giá' },
  ],
  2: [
    { value: '1', content: 'Đang làm' },
    { value: '2', content: 'Chờ đánh giá' },
    { value: '3', content: 'Hoàn thành' },
  ],
  3: [
    { value: '2', content: 'Chờ đánh giá' },
    { value: '3', content: 'Hoàn thành' },
  ],
};

const WORK_EVALUATION_OPTIONS = [
  { value: 'khong_danh_gia', content: 'Không đánh giá' },
  { value: 'dat', content: 'Đạt' },
  { value: 'khong_dat', content: 'Không đạt' },
];

const UpdateSubJob = ({ dataMainJob, dataSubJob, onCloseUpdateSubJob, reloadData }) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [userManagerList, setUserManagerList] = useState([]);
  const [clientCompanyList, setClientCompanyList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [reviewerList, setReviewerList] = useState([]);

  const [beginWork, setBeginWork] = useState(null);
  const [endWork, setEndWork] = useState(null);
  // Mục tiêu công việc
  const [workObjective, setWorkObjective] = useState('');
  // Noi dung công việc
  const [note, setNote] = useState('');
  // Người theo dõi
  const [arrManager, setArrManager] = useState([]);

  // Nguoi thuc hien
  const [assigner, setAssigner] = useState('');

  // Khách hàng
  const [clientCompanyId, setClientCompanyId] = useState(null);
  const [clientCompanyName, setClientCompanyName] = useState('');
  const [serviceId, setServiceId] = useState(null);

  const [status, setStatus] = useState(dataSubJob?.status);

  // Người đánh giá
  const [reviewer, setReviewer] = useState(null);

  // File đính kèm
  const [file, setFile] = useState([]);
  const [currentFile, setCurrentFile] = useState([]);

  // kết quả công việc
  const [workUpdateResult, setWorkUpdateResult] = useState(dataSubJob?.workUpdateResult);
  // Đánh giá công việc
  const [workEvaluation, setWorkEvaluation] = useState(dataSubJob?.workEvaluation);

  const getClientCompanies = async (dataMainJobRow, dataSubJobRow) => {
    const scheduleMainJobId = dataMainJobRow?.id || '';
    if (!scheduleMainJobId) return;

    try {
      const res = await ApiServices.getClientComp_ScheUserMain(scheduleMainJobId);
      if (res?.data) {
        setClientCompanyList(res?.data || []);

        if (res?.data) {
          const clientCompany = res?.data?.find(
            (item) => item.id === dataSubJobRow.clientCompanyId,
          );
          if (clientCompany) {
            setClientCompanyId(clientCompany);
            setClientCompanyName(clientCompany?.name || '');

            // Service List
            if (clientCompany?.arrService?.length) {
              setServiceList(clientCompany?.arrService);
            } else {
              setServiceList([]);
            }
            const service = clientCompany?.arrService?.find(
              (el) => el?.id === dataSubJobRow?.serviceId,
            );

            setServiceId(service);
          }
        }
      }
    } catch (error) {
      console.log('getClientCompanies Failed:::', error);
    }
  };

  const getUsers = async (rowData) => {
    try {
      const res = await ApiServices.getUsers();
      if (res?.data) {
        setUserManagerList(res?.data || []);
        setReviewerList(res?.data || []);

        const userManagerList = res?.data?.filter((item) =>
          rowData?.arrManager?.includes(item?.code),
        );
        setArrManager(userManagerList);

        const findAssigner = res?.data?.find((el) => el.code === rowData?.arrAssigner[0]);
        setAssigner(findAssigner);

        const reviewerList = res?.data?.find((item) => rowData?.reviewer === item?.code);
        setReviewer(reviewerList || null);
      }
    } catch (error) {
      console.log('getUsers Failed:::', error);
    }
  };

  useEffect(() => {
    if (dataSubJob?.beginWork) {
      setBeginWork(dataSubJob?.beginWork);
    }
    if (dataSubJob?.endWork) {
      setEndWork(dataSubJob?.endWork);
    }
    if (dataSubJob?.status) {
      setStatus(dataSubJob?.status);
    }
    if (dataSubJob?.note) {
      setNote(dataSubJob?.note);
    }
    const currentArrUrlfile = dataSubJob?.arrUrlfile;

    if (currentArrUrlfile?.length > 0) {
      setCurrentFile(currentArrUrlfile || []);
    }

    getUsers(dataSubJob);
    getClientCompanies(dataMainJob, dataSubJob);
  }, [dataMainJob, dataSubJob]);

  // Close modal
  const handleCloseModal = () => {
    onCloseUpdateSubJob();
  };

  const handleSubmit = async () => {
    const arrAssignerCode = Storage?.user?.Code || '';
    if (!arrAssignerCode) return;

    const arrManagerCode = arrManager?.map((item) => item?.code);
    const companyId = clientCompanyId?.id || '';
    const serId = serviceId?.id || '';
    const subJobId = dataSubJob?.id;
    const fileTmp = file?.map(([item]) => item);

    const strDateStart = moment(beginWork).format('YYYY-MM-DD HH:mm');
    const strDateEnd = moment(endWork).format('YYYY-MM-DD HH:mm');
    const dateStart = moment(strDateStart);
    const dateEnd = moment(strDateEnd);
    const compare = dateEnd.isBefore(dateStart);

    if (compare) {
      window.UIMessage?.error('Thời gian kết thúc phải lớn hơn Thời gian bắt đầu');
      setLoadingSubmit(false);
      return;
    }

    const dataSend = {
      name: dataSubJob?.name,
      beginWork: beginWork,
      endWork: endWork,
      note: note,
      status,
      workObjective: workObjective,
      arrManager: arrManagerCode || [],
      arrAssigner: [arrAssignerCode],
      clientCompanyId: companyId || '',
      clientCompanyName,
      serviceId: serId || '',
      workUpdateResult,
      workEvaluation,
      file: fileTmp,
      arrUrlfile: currentFile || [],
      // Thêm Công việc phụ Level phải = 1 và ParentScheduleUserCode không được trống
      level: 1,
      parentScheduleUserCode: dataMainJob?.code,
      reviewer: reviewer?.code || '',
    };

    setLoadingSubmit(true);
    return ApiServices.updateScheduleUserSubJob(subJobId, dataSend)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Cập nhật thành công');
          onCloseUpdateSubJob();
          reloadData();
        }
      })
      .catch((err) => {
        // window.UIMessage?.error('Cập nhật thất bại !!!');
        console.log(err);
      })
      .finally(() => setLoadingSubmit(false));
  };

  const handleChangeBeginWork = useCallback(
    (e) => {
      setBeginWork(e.target.value);
    },
    [beginWork],
  );

  const handleChangeEndWork = useCallback(
    (e) => {
      setEndWork(e.target.value);
    },
    [endWork],
  );
  const handleChangeWorkObjective = useCallback(
    (e) => {
      setWorkObjective(e.target.value);
    },
    [workObjective],
  );

  const handleChangeNote = useCallback(
    (e) => {
      setNote(e.target.value);
    },
    [note],
  );

  const handleChangeArrManager = useCallback(
    (_, newValue) => {
      setArrManager(newValue || null);
    },
    [arrManager],
  );
  const handleChangeReviewer = useCallback(
    (_, newValue) => {
      setReviewer(newValue || null);
    },
    [reviewer],
  );
  const handleChangeCustomer = useCallback(
    (_, newValue) => {
      setServiceId(null);

      setClientCompanyId(newValue || null);
      if (newValue) {
        setClientCompanyName(newValue?.name || newValue?.code);
        // Service List
        if (newValue?.arrService?.length) {
          setServiceList(newValue?.arrService);
        } else {
          setServiceList([]);
        }
      } else {
        setClientCompanyName('');
        setServiceList([]);
      }
    },
    [clientCompanyId],
  );

  const handleChangeService = useCallback(
    (_, newValue) => {
      setServiceId(newValue || null);
    },
    [serviceId],
  );

  const handleChangeStatus = useCallback(
    (e) => {
      setStatus(e.target.value);
    },
    [status],
  );

  const handleChangeFile = useCallback(
    (e) => {
      setFile(e.target.value);
    },
    [file],
  );
  const handleChangeCurrentFile = useCallback(
    (fileCurrent) => {
      setCurrentFile(fileCurrent || []);
    },
    [currentFile],
  );

  const handleChangeWorkUpdateResult = useCallback(
    (e) => {
      setWorkUpdateResult(e.target.value);
    },
    [workUpdateResult],
  );

  const handleChangeWorkEvaluate = useCallback(
    (e) => {
      setWorkEvaluation(e.target.value);
    },
    [workEvaluation],
  );

  return (
    <Box>
      <Box style={{ fontSize: '20px', marginTop: '8px' }}>
        <b>Mục tiêu công việc:</b> <span>{dataSubJob?.workObjective}</span>
      </Box>
      <Grid container spacing={1} style={{ marginTop: '4px' }}>
        <Grid item xs={6} sm={6} md={6}>
          {/* <TextInput
            required
            error={!beginWork}
            helperText={!beginWork ? 'Thời gian bắt đầu không được để trống' : ''}
            label="Thời gian bắt đầu"
            name="beginWork"
            onChange={handleChangeBeginWork}
            value={beginWork}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          /> */}

          <DateTimePicker
            error={!beginWork}
            helperText={!beginWork ? 'Thời gian bắt đầu không được để trống' : ''}
            label="Thời gian bắt đầu"
            name="beginWork"
            onChange={handleChangeBeginWork}
            value={beginWork}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          {/* <TextInput
            required
            error={!endWork}
            helperText={!endWork ? 'Thời gian kết thúc không được để trống' : ''}
            label="Thời gian kết thúc"
            name="endWork"
            onChange={handleChangeEndWork}
            value={endWork}
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
          /> */}
          <DateTimePicker
            error={!endWork}
            helperText={!endWork ? 'Thời gian kết thúc không được để trống' : ''}
            label="Thời gian kết thúc"
            name="endWork"
            onChange={handleChangeEndWork}
            value={endWork}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <Selection
            label="Trạng thái"
            size="small"
            displayNone={false}
            dataArrObject={STATUS_OPTIONS[dataSubJob?.status] || []}
            onChange={handleChangeStatus}
            value={status}
          />
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <TextInput label="Người thực hiện" name="name" value={assigner?.name || ''} />
        </Grid>

        <Grid item xs={6} sm={6} md={6}>
          <ComboBox
            options={clientCompanyList}
            label="Mã khách hàng"
            sx={{ width: '100%', marginTop: '8px' }}
            keyField="code"
            value={clientCompanyId}
            onChange={handleChangeCustomer}
          />
        </Grid>
        <Grid item xs={6} sm={6} md={6}>
          <ComboBox
            options={serviceList}
            label="Dịch vụ"
            sx={{ width: '100%', marginTop: '8px' }}
            keyField="name"
            value={serviceId}
            onChange={handleChangeService}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextInput
            required
            multiline={true}
            rows={4}
            error={!note}
            helperText={!note ? 'Nội dung công việc không được để trống' : ''}
            label="Nội dung công việc"
            name="note"
            onChange={handleChangeNote}
            value={note}
          />
        </Grid>

        <FileUploadScheduleUser
          label="File đính kèm"
          name="file"
          marinTop={1}
          // onBlur={handleBlur}
          onChange={handleChangeFile}
          value={file}
          multiple={true}
          isUploadOnlyContract={true}
          valueCurrent={currentFile}
          onChangeCurrent={handleChangeCurrentFile}
          limitLength={50}
          limitLengthStart={30}
          limitLengthEnd={20}
        />

        <Grid item xs={12} sm={12} md={12} style={{ marginTop: '8px' }}>
          <TextInput
            label="Kết quả công việc"
            name="name"
            value={workUpdateResult}
            onChange={handleChangeWorkUpdateResult}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <ComboBox
            disableCloseOnSelect
            options={userManagerList}
            label="Người theo dõi"
            sx={{ width: '100%', marginTop: '12px' }}
            keyField="name"
            value={arrManager}
            onChange={handleChangeArrManager}
            multiple
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <ComboBox
            error={!reviewer}
            helperText={!reviewer && 'Vui lòng chọn Người đánh giá'}
            // disableCloseOnSelect
            options={reviewerList}
            label="Người đánh giá"
            sx={{ width: '100%', marginTop: '12px' }}
            keyField="name"
            value={reviewer}
            onChange={handleChangeReviewer}
          />
        </Grid>
      </Grid>

      <Grid container spacing={1} style={{ marginTop: '4px' }}>
        <Grid item xs={6} sm={6} md={6}>
          <Selection
            label="Đánh giá công việc"
            size="small"
            displayNone={false}
            dataArrObject={WORK_EVALUATION_OPTIONS}
            onChange={handleChangeWorkEvaluate}
            value={workEvaluation}
          />
        </Grid>
      </Grid>

      <Box sx={{ display: 'flex', py: 2 }}>
        <Box sx={{ marginLeft: 'auto' }}>
          <BtnCreateUpdate color="error" variant="contained" onClick={handleCloseModal}>
            Hủy
          </BtnCreateUpdate>
          {/* {dataSubJob?.status !== 3 && ( */}
          <BtnCreateUpdate
            color="primary"
            loading={loadingSubmit}
            // disabled={isSubmitting}
            variant="contained"
            onClick={handleSubmit}
          >
            Lưu
          </BtnCreateUpdate>
          {/* )} */}
        </Box>
      </Box>
    </Box>
  );
};

export { UpdateSubJob };
