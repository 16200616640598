import * as React from 'react';
import { TextField, FormControl, InputLabel, IconButton } from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import moment from 'moment';
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import ClearIcon from '@mui/icons-material/Clear';

export const DatePickerSearchColumn = ({
  label,
  inputFormat = 'dd/MM/yyyy',
  onChange,
  value,
  name,
  type = 'text',
  size = 'small',
  margin = 'dense',
  fullWidth = true,
  variant = 'standard',
  error = false,
  helperText = '',
  marginTop = '3',
  width = '150px',
  ...props
}) => {
  const [dateValue, setDateValue] = React.useState(null);

  React.useEffect(() => {
    value && setDateValue(new Date(moment(value).format('YYYY-MM-DD')));
  }, [value]);

  const onClear = () => {
    setDateValue(null);

    onChange &&
      onChange({
        persist: () => {},
        target: {
          type: 'change',
          name,
          value: null,
        },
      });
  };

  const handleChange = (newValue) => {
    setDateValue(newValue);

    onChange &&
      onChange({
        persist: () => {},
        target: {
          type: 'change',
          name,
          value: moment(newValue).format('YYYY-MM-DD'),
        },
      });
  };
  return (
    <>
      <FormControl
        {...{
          fullWidth,
          type,
          size,
          variant,
          // margin,
        }}
        sx={{ mt: marginTop, width: width }}
      >
        {/* <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel> */}
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DesktopDatePicker
            {...{
              fullWidth,
              type,
              size,
              variant,
              margin,
              variant,
              inputFormat,
              label,
            }}
            value={dateValue}
            onChange={handleChange}
            renderInput={(params) => (
              <div style={{ position: 'relative', display: 'inline-block' }}>
                <TextFieldCustom size={size} {...params} />
                {dateValue && (
                  <IconButton
                    style={{ position: 'absolute', top: 0, margin: 'auto', right: '25px' }}
                    onClick={() => onClear()}
                  >
                    <ClearIcon
                      sx={{
                        fontSize: '1rem',
                        color: '#000',
                        '&:hover': {
                          color: '#000',
                        },
                      }}
                    />
                  </IconButton>
                )}
              </div>
            )}
          />
        </LocalizationProvider>
      </FormControl>
      {error && (
        <div style={{ color: '#d32f2f', fontSize: '14px', marginTop: '3px' }}>{helperText}</div>
      )}
    </>
  );
};

export const TextFieldCustom = styled(TextField)(({ theme }) => ({
  marginTop: '1px',
  '.MuiInputBase-root': {
    paddingRight: '5px',
  },
  '.MuiInputBase-input': {
    padding: '7px 0px 7px 5px',
    height: '15px',
    fontSize: '13px',
  },
  '.MuiSvgIcon-root': {
    with: '13px',
    height: '1rem',
  },
}));
