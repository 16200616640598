import React, { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  AvatarGroup,
  Box,
  Tooltip,
  Typography,
  IconButton,
  Stack,
} from '@mui/material';

import SearchIcon from '@mui/icons-material/Search';
import {
  BsBoxArrowInUpRight,
  BsArrowDownCircle,
  BsPlusCircle,
  BsFilter,
  BsFillStickiesFill,
} from 'react-icons/bs';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import {
  ModalFormDetail,
  SearchInputColumnNew,
  DatePicker,
  SelectionSearchColumn,
  TextInputSearchColumn,
  DatePickerSearchColumn,
} from 'components';
import { RESPONSE_STATUSES, TASK_JOB_STATUS_OPTIONS } from 'commons/constants';

import ApiServices from 'services';
import { FormDetail } from './form-detail';
import Utils from 'commons/utils';
import { debounce, pad } from 'lodash';
import { ContainerHeader, ButtonAdd, ButtonImport, ButtonExport } from './styles';

import { UpdateJob } from './update-job';
import { UpdateSubJob } from './update-sub-job';

import { RenderTaskJobStatus } from 'components/custom-status';

// const renderStatus = (status) => {
//   const jobStatus = {
//     0: 'Kế hoạch',
//     1: 'Đang làm',
//     2: 'Chờ đánh giá',
//     3: 'Hoàn thành',
//   };

//   return jobStatus[status] || '';
// };

const renderCreateBy = (row, usersList) => {
  const user = usersList?.find((item) => item?.code === row?.createdBy);

  if (user) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Avatar
          alt={user.code}
          src={`${process.env.REACT_APP_API_URL}/${user?.urlAvatar}`}
          // sx={{ width: 28, height: 28 }}
        />
        <Box sx={{ ml: 1 }}>
          <Box sx={{ fontWeight: 500 }}>{user?.code}</Box>
          {/* <Box sx={{ color: 'text.secondary', fontSize: '0.75rem' }}>({user?.code})</Box> */}
        </Box>
      </Box>
    );
  }
  return row?.createdBy;
};

const renderUserFollow = (arrUser, usersList) => {
  if (!arrUser?.length) return '';

  const userManager = usersList?.filter((item) => arrUser?.includes(item?.code));

  if (!userManager?.length) return '';

  return (
    <AvatarGroup max={5} sx={{ marginLeft: 'auto', marginRight: '15px', cursor: 'pointer' }}>
      {userManager.map((item) => (
        <Tooltip key={item?.code} title={item.name} placement="top-start" arrow>
          <Avatar
            alt={item.code}
            src={`${process.env.REACT_APP_API_URL}/${item?.urlAvatar}`}
            // sx={{ width: 28, height: 28 }}
          />
        </Tooltip>
      ))}
    </AvatarGroup>
  );
};

function Row(props) {
  const {
    row,
    deleteItem,
    setSelected,
    usersList,
    handleShowDetailMainJob,
    handleShowDetailSubJob,
  } = props;
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (row) {
      setOpen(row?.isCollapse);
    }
  }, [row]);

  return (
    <React.Fragment>
      {/* // ================== Main Job ==================
          // ================== Main Job ==================
          // ================== Main Job ==================
          // ================== Main Job ================== */}
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell
          sx={{
            border: '1px solid blue',
          }}
        >
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {row?.submenus?.length > 0 ? (
              open ? (
                <KeyboardArrowDownIcon style={{ color: 'blue', transition: '0.25s' }} />
              ) : (
                <KeyboardArrowRightIcon style={{ color: 'blue', transition: '0.25s' }} />
              )
            ) : (
              <></>
            )}
          </IconButton>
        </TableCell>
        <TableCell
          align="center"
          style={{ fontWeight: '400' }}
          sx={{
            border: '1px solid blue',
          }}
        >
          <Box sx={{ width: '80px' }}>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => handleShowDetailMainJob(row)}
            >
              <BorderColorIcon />
            </IconButton>
            <IconButton
              sx={{ marginLeft: '5px' }}
              aria-label="expand row"
              size="small"
              onClick={() => window.showConfirm(() => deleteItem(row))}
            >
              <HighlightOffIcon style={{ color: 'red' }} />
            </IconButton>
          </Box>
        </TableCell>
        <TableCell
          align="center"
          style={{ fontWeight: '400' }}
          sx={{
            border: '1px solid blue',
          }}
        >
          {row?.code}
        </TableCell>
        <TableCell
          align="center"
          style={{ fontWeight: '400' }}
          sx={{
            border: '1px solid blue',
          }}
        >
          {row?.name}
        </TableCell>
        <TableCell
          align="center"
          style={{ fontWeight: '400' }}
          sx={{
            border: '1px solid blue',
          }}
        >
          {row?.workObjective}
        </TableCell>
        <TableCell
          align="center"
          style={{ fontWeight: '400' }}
          sx={{
            border: '1px solid blue',
          }}
        >
          {row?.beginWork && Utils.formatDateFn(row.beginWork)}
        </TableCell>
        <TableCell
          align="center"
          style={{ fontWeight: '400' }}
          sx={{
            border: '1px solid blue',
          }}
        >
          {row?.endWork && Utils.formatDateFn(row.endWork)}
        </TableCell>
        <TableCell
          align="center"
          style={{ fontWeight: '400' }}
          sx={{
            border: '1px solid blue',
          }}
        >
          {row?.workUpdateResult}
        </TableCell>
        <TableCell
          align="center"
          style={{ fontWeight: '400' }}
          sx={{
            border: '1px solid blue',
          }}
        >
          {renderCreateBy(row, usersList)}
        </TableCell>
        <TableCell
          align="center"
          style={{ fontWeight: '400' }}
          sx={{
            border: '1px solid blue',
          }}
        >
          {renderUserFollow(row?.arrManager || [], usersList)}
        </TableCell>
        <TableCell
          align="center"
          style={{ fontWeight: '400' }}
          sx={{
            border: '1px solid blue',
          }}
        >
          {renderUserFollow(row?.arrAssigner || [], usersList)}
        </TableCell>
        <TableCell
          align="center"
          style={{ fontWeight: '400' }}
          sx={{
            border: '1px solid blue',
          }}
        >
          {/* {renderStatus(row?.status)} */}
          <RenderTaskJobStatus status={row?.status} />
        </TableCell>
      </TableRow>
      {/* // ================== Sub Job ==================
          // ================== Sub Job ==================
          // ================== Sub Job ==================
          // ================== Sub Job ================== */}
      {open && (
        <>
          {row?.submenus?.map((sub, index) => {
            return (
              <TableRow
                key={`${sub?.code}-${sub?.parent}-${index}`}
                sx={{ backgroundColor: '#f5f5f5', boxShadow: '5px 5px 10px 0 #eee' }}
              >
                <TableCell sx={{ border: 'none' }}>
                  <b
                    style={{
                      marginLeft: '10px',
                    }}
                  ></b>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    border: '1px solid blue',
                  }}
                >
                  <Box sx={{ width: '80px' }}>
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      onClick={() => handleShowDetailSubJob(sub)}
                    >
                      <BorderColorIcon />
                    </IconButton>
                    <IconButton
                      sx={{ marginLeft: '5px' }}
                      aria-label="expand row"
                      size="small"
                      onClick={() => window.showConfirm(() => deleteItem(sub))}
                    >
                      <HighlightOffIcon style={{ color: 'red' }} />
                    </IconButton>
                  </Box>
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    border: '1px solid blue',
                  }}
                >
                  {sub?.code}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    border: '1px solid blue',
                  }}
                >
                  {sub?.name}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    border: '1px solid blue',
                  }}
                >
                  {sub?.workObjective}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    border: '1px solid blue',
                  }}
                >
                  {sub?.beginWork && Utils.formatDateFn(sub.beginWork)}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    border: '1px solid blue',
                  }}
                >
                  {sub?.endWork && Utils.formatDateFn(sub.endWork)}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    border: '1px solid blue',
                  }}
                >
                  {sub?.workUpdateResult}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    border: '1px solid blue',
                  }}
                >
                  {renderCreateBy(sub, usersList)}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    border: '1px solid blue',
                  }}
                >
                  {renderUserFollow(sub?.arrManager || [], usersList)}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    border: '1px solid blue',
                  }}
                >
                  {renderUserFollow(sub?.arrAssigner || [], usersList)}
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    border: '1px solid blue',
                  }}
                >
                  {/* {renderStatus(sub?.status)} */}
                  <RenderTaskJobStatus status={sub?.status} />
                </TableCell>
              </TableRow>
            );
          })}
        </>
      )}
    </React.Fragment>
  );
}

const START_DATE = moment().startOf('month').format();
const END_DATE = moment().endOf('date').format();

// ================== TaskJobPage ==================
// ================== TaskJobPage ==================
// ================== TaskJobPage ==================
// ================== TaskJobPage ==================

const TaskJobPage = () => {
  const inputRef = useRef();
  const [originData, setOriginData] = useState([]);
  const [dataFormat, setDataFormat] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(null);
  const [filter, setFilter] = useState(null);
  const [filterColumn, setFilterColumn] = useState(null);

  const [loadingImport, setLoadingImport] = useState(false);
  const [usersList, setUsersList] = useState([]);
  // search Date
  const [startDate, setStartDate] = useState(START_DATE);
  const [endDate, setEndDate] = useState(END_DATE);

  const [selectedShowModal, setSelectedShowModal] = useState(null);
  const [selectedUpdateSubJob, setSelectedUpdateSubJob] = useState(null);
  const [dataMainBySubJob, setDataMainBySubJob] = useState(null);

  const [searchStatus, setSearchStatus] = useState('all');
  const [searchBeginWork, setSearchBeginWork] = useState(null);
  const [searchEndWork, setSearchEndWork] = useState(null);

  const initData = async (query) => {
    setLoading(true);
    try {
      const res = await ApiServices.getSchedulePersonUsers(query ? query : filter);
      if (res?.data) {
        const filterParent = res?.data?.filter((el) => !el?.parentScheduleUserCode);
        const dataFormat = filterParent?.map((item) => {
          const filterSubmenu = res?.data?.filter(
            (element) => element?.parentScheduleUserCode === item?.code,
          );
          item.submenus = filterSubmenu || [];
          item.isCollapse = false;
          return item;
        });

        setDataFormat(dataFormat || []);
        setOriginData(res?.data || []);
      }
    } catch (err) {
      console.warn('initData - getSchedulePersonUsers:', err);
    }
    setLoading(false);
  };

  const getUsers = async () => {
    try {
      const res = await ApiServices.getUsers();
      if (res?.data) {
        setUsersList(res?.data || []);
      }
    } catch (error) {
      console.log('getUsers Failed:::', error);
    }
  };

  const handleFilterData = (query, dataInit) => {
    let dataFilter = [];

    dataInit?.map((item) => {
      let checkFilter = true;

      if (query?.code) {
        if (!Utils.convertValSearch(item?.code)?.includes(Utils.convertValSearch(query?.code))) {
          checkFilter = false;
        }
      }
      if (query?.name) {
        if (!Utils.convertValSearch(item?.name)?.includes(Utils.convertValSearch(query?.name))) {
          checkFilter = false;
        }
      }
      if (query?.workObjective) {
        if (
          !Utils.convertValSearch(item?.workObjective)?.includes(
            Utils.convertValSearch(query?.workObjective),
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.workUpdateResult) {
        if (
          !Utils.convertValSearch(item?.workUpdateResult)?.includes(
            Utils.convertValSearch(query?.workUpdateResult),
          )
        ) {
          checkFilter = false;
        }
      }
      if (query?.beginWork) {
        if (moment(item?.beginWork).format('YYYY-MM-DD') !== query?.beginWork) {
          checkFilter = false;
        }
      }
      if (query?.endWork) {
        if (moment(item?.endWork).format('YYYY-MM-DD') !== query?.endWork) {
          checkFilter = false;
        }
      }
      if (query?.status) {
        if (item?.status !== +query?.status) {
          checkFilter = false;
        }
      }

      if (checkFilter) {
        dataFilter.push(item);
        return;
      } else {
        return;
      }
    });

    const filterParent = dataInit?.filter((el) => !el?.parentScheduleUserCode);
    const dataParentIsFilter = dataFilter
      ?.filter((parentIsFilter) => !parentIsFilter?.parentScheduleUserCode)
      ?.map((isFilter) => isFilter?.id);

    const dataFormat = filterParent
      ?.map((item) => {
        const filterSubmenu = dataFilter?.filter(
          (element) => element?.parentScheduleUserCode === item?.code,
        );
        item.submenus = filterSubmenu || [];
        item.isCollapse =
          query?.code ||
          query?.name ||
          query?.workObjective ||
          query?.workUpdateResult ||
          query?.beginWork ||
          query?.endWork ||
          query?.status
            ? true
            : false;
        return item;
      })
      ?.filter(
        (dataFormatFilter) =>
          dataFormatFilter?.submenus?.length || dataParentIsFilter?.includes(dataFormatFilter?.id),
      );

    setDataFormat(dataFormat || []);
  };

  const timeoutGetData_Init = useCallback(
    debounce((newFilter) => {
      initData(newFilter);
    }, 500),
    [],
  );
  useEffect(() => {
    timeoutGetData_Init(filter);
  }, [filter]);

  useEffect(() => {
    getUsers();
  }, []);

  const reloadData = async () => {
    return await initData(filter);
  };

  const timeoutGetData = useCallback(
    debounce((newFilter, dataInit) => {
      handleFilterData(newFilter, dataInit);
    }, 500),
    [],
  );

  useEffect(() => {
    if (originData?.length) {
      timeoutGetData(filterColumn, originData);
    }
  }, [filterColumn]);

  const onColumnSearch = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setFilterColumn((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleSearchSelect = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    setSearchStatus(value);

    if (value === 'all') value = '';

    setFilterColumn((prev) => {
      return { ...prev, [name]: value };
    });
  };

  const handleChangeSearchBeginWork = (event) => {
    const date = event.target.value;
    setSearchBeginWork(date);
    const dateSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    setFilterColumn((prev) => {
      return { ...prev, beginWork: dateSearch };
    });
  };
  const handleChangeSearchEndWork = (event) => {
    const date = event.target.value;
    setSearchEndWork(date);
    const dateSearch = date ? moment(date).format('YYYY-MM-DD') : null;
    setFilterColumn((prev) => {
      return { ...prev, endWork: dateSearch };
    });
  };

  const deleteItem = async (row) => {
    if (!row?.id) return;

    setLoading(true);
    return ApiServices.deleteScheduleUser(row?.id)
      .then((res) => {
        if (res.status === RESPONSE_STATUSES.OK) {
          window.UIMessage?.success('Xóa thành công');
          reloadData();
        }
      })
      .catch((err) => {
        // window.UIMessage?.error('Xóa thất bại !!!');
        console.warn('deleteClientComplaint', err);
      })
      .finally(() => setLoading(false));
  };

  const validateValue = (row) => {
    const dataSelect = row;
    dataSelect.beginWork = Utils.checkIsDate(row.beginWork)
      ? Utils.convertToDateTimeFormat(row.beginWork)
      : '';
    dataSelect.endWork = Utils.checkIsDate(row.endWork)
      ? Utils.convertToDateTimeFormat(row.endWork)
      : '';
    return dataSelect;
  };
  const onCloseModal = () => {
    setSelectedShowModal(null);
  };
  const handleShowDetailMainJob = (row) => {
    const dataSelect = validateValue(row);
    setSelectedShowModal(dataSelect);
  };

  // Sub Job
  const handleCloseModalUpdateSubJob = () => {
    setSelectedUpdateSubJob(null);
    setDataMainBySubJob(null);
  };
  const handleShowDetailSubJob = (row) => {
    const dataMain = originData?.find((item) => item?.code === row?.parentScheduleUserCode);
    setDataMainBySubJob(dataMain);

    const dataSelect = validateValue(row);

    setSelectedUpdateSubJob(dataSelect);
  };

  const reloadDataAfterUpdateSubJob = () => {
    handleCloseModalUpdateSubJob();
    reloadData();
  };

  const handleChangeStartDate = useCallback(
    (event) => {
      setStartDate(event.target.value);
    },
    [startDate],
  );
  const handleChangeEndDate = useCallback(
    (event) => {
      setEndDate(event.target.value);
    },
    [endDate],
  );

  const handleSearchDate = (startDateSearch, endDateSearch) => {
    if (!startDateSearch || !endDateSearch) return;
    setFilter((prev) => ({ ...prev, DateFrom: startDateSearch, DateTo: endDateSearch }));
  };

  const renderColumn = (
    <TableRow
      sx={{
        backgroundColor: '#E8F9FF',
        border: '1px solid blue',
        height: '50px',
        padding: '0px',
        padding: '8px',
      }}
    >
      <TableCell
        align="center"
        width={'50px'}
        sx={{
          border: '1px solid blue',
          padding: '8px',
        }}
      >
        #
      </TableCell>
      <TableCell
        align="center"
        width={'100px'}
        minWidth="100px"
        sx={{
          border: '1px solid blue',
          padding: '8px',
        }}
      >
        #
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: '1px solid blue',
          padding: '8px',
        }}
      >
        <SearchInputColumnNew name="code" title="Mã công việc" onChange={onColumnSearch} />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: '1px solid blue',
          padding: '8px',
        }}
      >
        <SearchInputColumnNew name="name" title="Tên công việc" onChange={onColumnSearch} />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: '1px solid blue',
          padding: '8px',
        }}
      >
        <SearchInputColumnNew name="workObjective" title="Mục tiêu CV" onChange={onColumnSearch} />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: '1px solid blue',
          padding: '8px',
        }}
      >
        T.gian bắt đầu
        <DatePickerSearchColumn
          sx={{
            marginBottom: '10px',
            padding: 0,
          }}
          width={'140px'}
          marginTop="0"
          size="small"
          onChange={handleChangeSearchBeginWork}
          value={searchBeginWork}
          error={true}
          label=""
        />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: '1px solid blue',
          padding: '8px',
        }}
      >
        T.gian kết thúc
        <DatePickerSearchColumn
          sx={{
            marginBottom: '10px',
            padding: 0,
          }}
          width={'140px'}
          marginTop="0"
          size="small"
          onChange={handleChangeSearchEndWork}
          value={searchEndWork}
          error={true}
          label=""
        />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: '1px solid blue',
          padding: '8px',
        }}
      >
        <SearchInputColumnNew
          name="workUpdateResult"
          title="Kết quả CV"
          onChange={onColumnSearch}
        />
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: '1px solid blue',
          padding: '8px',
        }}
      >
        Người tạo
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: '1px solid blue',
          padding: '8px',
        }}
      >
        Người theo dõi
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: '1px solid blue',
          padding: '8px',
        }}
      >
        Người T.Hiện
      </TableCell>
      <TableCell
        align="center"
        sx={{
          border: '1px solid blue',
          padding: '8px',
        }}
      >
        <b>Trạng thái</b>
        <SelectionSearchColumn
          size="small"
          label=""
          name="status"
          dataArrObject={TASK_JOB_STATUS_OPTIONS}
          value={searchStatus}
          onChange={handleSearchSelect}
          displayNone={false}
        />
      </TableCell>
    </TableRow>
  );

  const renderHeader = (
    <>
      <ContainerHeader>
        <Stack spacing={2} direction="row">
          <Box sx={{ width: '160px' }}>
            <DatePicker
              sx={{
                marginBottom: '10px',
                padding: 0,
              }}
              width={100}
              marginTop="0"
              size="small"
              onChange={handleChangeStartDate}
              value={startDate}
              error={true}
              label="Từ ngày"
            />
          </Box>
          <Box sx={{ width: '160px' }}>
            <DatePicker
              sx={{
                marginBottom: '10px',
                padding: 0,
              }}
              width={100}
              marginTop="0"
              size="small"
              onChange={handleChangeEndDate}
              value={endDate}
              error={true}
              label="Đến ngày"
            />
          </Box>

          <IconButton
            aria-label="filter"
            sx={{ color: 'blue' }}
            onClick={() => handleSearchDate(startDate, endDate)}
          >
            <SearchIcon />
          </IconButton>
          {/* <IconButton aria-label="filter" sx={{ color: 'red' }}>
            <SearchOffIcon />
          </IconButton> */}
        </Stack>
      </ContainerHeader>

      {/* <IconButton aria-label="filter" sx={{ color: '#6C757D' }}>
        <BsFilter />
      </IconButton> */}
      {/* <ButtonImport
        variant="outlined"
        component="label"
        startIcon={<BsBoxArrowInUpRight />}
        disabled={loadingImport}
      >
        Nhập excel
        <input
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          type="file"
          hidden
          ref={inputRef}
          // onChange={onHandleImport}
        />
      </ButtonImport> */}
      {/* <ButtonExport
        variant="outlined"
        startIcon={<BsArrowDownCircle />}
        // onClick={handleExportClient}
      >
        Tải excel
      </ButtonExport> */}
      {/* <ButtonExport
        variant="outlined"
        startIcon={<BsFillStickiesFill />}
        // onClick={handleExportTemplate}
      >
        Biểu mẫu
      </ButtonExport> */}
      <ButtonAdd
        variant="contained"
        startIcon={<BsPlusCircle />}
        onClick={() => setSelected({ isNew: true })}
      >
        Thêm mới
      </ButtonAdd>
    </>
  );

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Typography variant="h6" component="h6" sx={{ marginBottom: '5px' }}>
          DANH SÁCH CÔNG VIỆC
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '20px 10px',
          }}
        >
          {renderHeader}
        </Box>

        <Table aria-label="collapsible table">
          <TableHead>{renderColumn}</TableHead>

          <TableBody>
            {dataFormat?.map((row, index) => (
              <Row
                key={`${row?.code}-${row?.name}-${index}`}
                row={row}
                deleteItem={deleteItem}
                setSelected={setSelected}
                usersList={usersList || []}
                handleShowDetailMainJob={handleShowDetailMainJob}
                handleShowDetailSubJob={handleShowDetailSubJob}
              />
            ))}
          </TableBody>
        </Table>
        <FormDetail {...{ selected, setSelected, reloadData }} />
        {/* // Update Main Job  */}
        <ModalFormDetail
          maxWidth="md"
          title={`Cập nhật: ${selectedShowModal?.name} `}
          open={selectedShowModal}
          onCloseModal={onCloseModal}
        >
          <UpdateJob
            selectedShowModal={selectedShowModal}
            setSelectedShowModal={setSelectedShowModal}
            onCloseModal={onCloseModal}
            reloadData={reloadData}
          />
        </ModalFormDetail>

        {/* // Update Sub Job  */}
        <ModalFormDetail
          maxWidth="md"
          title={`Cập nhật: ${selectedUpdateSubJob?.name}`}
          open={selectedUpdateSubJob}
          onCloseModal={handleCloseModalUpdateSubJob}
        >
          <UpdateSubJob
            dataMainJob={dataMainBySubJob}
            dataSubJob={selectedUpdateSubJob}
            onCloseUpdateSubJob={handleCloseModalUpdateSubJob}
            reloadData={reloadDataAfterUpdateSubJob}
          />
        </ModalFormDetail>
      </TableContainer>
    </>
  );
};

export { TaskJobPage };
