import React from 'react';
import {
  Box,
  Grid,
  Button,
  Checkbox,
  IconButton,
  Avatar,
  AvatarGroup,
  Tooltip,
} from '@mui/material';
import Utils from 'commons/utils';
import { Table } from 'components';
import DeleteIcon from '@mui/icons-material/Delete';

const renderStatus = (status) => {
  if (status === 3) {
    return <Checkbox checked color="primary" />;
  }

  return <Checkbox color="default" />;
};

const renderAssigner = (rowData, dataAssignerList) => {
  const findData = dataAssignerList?.find((item) => item?.code === rowData?.arrAssigner?.[0]);
  if (findData) {
    return (
      <AvatarGroup max={5} sx={{ marginLeft: 'auto', marginRight: '15px', cursor: 'pointer' }}>
        <Tooltip key={findData?.code} title={findData.name} placement="top-start" arrow>
          <Avatar
            alt={findData.code}
            src={`${process.env.REACT_APP_API_URL}/${findData?.urlAvatar}`}
            // sx={{ width: 28, height: 28 }}
          />
        </Tooltip>
      </AvatarGroup>
    );
  }
  return '';
};

const DisplaySubJob = ({
  subJobList = [],
  handleDeleteSubJob,
  setSelectedUpdateSubJob,
  userAssignerList,
}) => {
  const validateValue = (row) => {
    const dataSelect = row;
    dataSelect.beginWork = Utils.checkIsDate(row.beginWork)
      ? Utils.convertToDateTimeFormat(row.beginWork)
      : '';
    dataSelect.endWork = Utils.checkIsDate(row.endWork)
      ? Utils.convertToDateTimeFormat(row.endWork)
      : '';
    return dataSelect;
  };

  const handleClickDetail = (row) => {
    const dataSelect = validateValue(row);
    setSelectedUpdateSubJob(dataSelect);
  };

  const columns = [
    {
      id: 'status',
      numeric: true,
      disablePadding: false,
      label: '#',
      align: 'center',
      width: '50px',
      render: (row) => renderStatus(row?.status),
    },
    {
      id: 'code',
      numeric: true,
      disablePadding: false,
      label: 'Mã CV con',
      align: 'center',
      width: '150px',
      render: (row) => {
        return (
          <Button
            sx={{
              textTransform: 'none',
            }}
            variant="text"
            onClick={() => handleClickDetail(row)}
          >
            {row?.code}
          </Button>
        );
      },
    },
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: 'Tên CV con',
      align: 'center',
      width: '150px',
    },
    {
      id: 'beginWork',
      numeric: true,
      disablePadding: false,
      label: 'T.gian bắt đầu',
      align: 'center',
      width: '130px',
      render: (row) => Utils.formatDateFn(row?.beginWork),
    },
    {
      id: 'endWork',
      numeric: true,
      disablePadding: false,
      label: 'T.gian kết thúc',
      align: 'center',
      width: '130px',
      render: (row) => Utils.formatDateFn(row?.endWork),
    },
    {
      id: 'arrAssigner',
      numeric: true,
      disablePadding: false,
      label: 'Người T.Hiện',
      align: 'center',
      width: '90px',
      render: (row) => renderAssigner(row, userAssignerList),
    },
    {
      id: 'note',
      numeric: true,
      disablePadding: false,
      label: '#',
      align: 'center',
      width: '60px',
      render: (row) => (
        <IconButton color="error" size="small" onClick={() => handleDeleteSubJob(row)}>
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  return (
    <Table
      rows={subJobList || []}
      headCells={columns}
      // onPageChange={(paging) => {}}
      isHeader={false}
      // onClickDetail={handleClickDetail}
    />
  );
};

export { DisplaySubJob };
