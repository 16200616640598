import React, { memo } from 'react';
import { Chip } from '@mui/material';

export const RenderPriceBoardStatus = memo(({ status }) => {
  if (!Number.isInteger(status)) return '';
  switch (status) {
    case 0:
      return <Chip label="Chờ xác nhận" color="warning" size="small" sx={{ paddingX: '10px' }} />;
    case 1:
      return <Chip label="Xác nhận" color="success" size="small" sx={{ paddingX: '10px' }} />;
    case 9:
      return <Chip label="Từ chối" color="error" size="small" sx={{ paddingX: '10px' }} />;
    default:
      return '';
  }
});

export const RenderPriceBoardState = memo(({ state }) => {
  if (!state) return '';
  return <i style={{ color: 'red' }}>Hết hạn</i>;
});

export const RenderQuoteStatus = memo(({ status }) => {
  if (!Number.isInteger(status)) return '';
  switch (status) {
    case 0:
      return <Chip label="Khởi tạo" size="small" sx={{ paddingX: '10px' }} />;
    case 1:
      return <Chip label="Trình duyệt" color="warning" size="small" sx={{ paddingX: '10px' }} />;
    case 2:
      return <Chip label="Đã duyệt" color="success" size="small" sx={{ paddingX: '10px' }} />;
    case 9:
      return <Chip label="Không duyệt" color="error" size="small" sx={{ paddingX: '10px' }} />;
    case 10:
      return <Chip label="Đã gửi" color="success" size="small" sx={{ paddingX: '10px' }} />;
    case 11:
      return <Chip label="Chấp nhận" color="success" size="small" sx={{ paddingX: '10px' }} />;
    case 19:
      return <Chip label="Từ chối" color="error" size="small" sx={{ paddingX: '10px' }} />;

    default:
      return '';
  }
});
export const RenderContractStatus = memo(({ status }) => {
  if (!Number.isInteger(status)) return '';
  switch (status) {
    case 0:
      return <Chip label="Khởi tạo" size="small" sx={{ paddingX: '10px' }} />;
    case 1:
      return <Chip label="Trình ký" color="primary" size="small" sx={{ paddingX: '10px' }} />;
    case 2:
      return <Chip label="Xác nhận" color="success" size="small" sx={{ paddingX: '10px' }} />;
    case 3:
      return <Chip label="Hết hạn" color="warning" size="small" sx={{ paddingX: '10px' }} />;
    case 10:
      return <Chip label="Hủy" color="error" size="small" sx={{ paddingX: '10px' }} />;

    default:
      return '';
  }
});
export const RenderCheckPriceStatus = memo(({ status }) => {
  if (!Number.isInteger(status)) return '';
  switch (status) {
    case 0:
      return <Chip label="Khởi tạo" size="small" sx={{ paddingX: '10px' }} />;
    case 1:
      return <Chip label="Chờ phản hồi" color="warning" size="small" sx={{ paddingX: '10px' }} />;
    case 2:
      return <Chip label="Đang xử lý" color="primary" size="small" sx={{ paddingX: '10px' }} />;
    case 3:
      return <Chip label="Đã phản hồi" color="success" size="small" sx={{ paddingX: '10px' }} />;
    case 9:
      return <Chip label="Từ chối" color="error" size="small" sx={{ paddingX: '10px' }} />;
    default:
      return '';
  }
});
export const RenderCheckPriceState = memo(({ state }) => {
  if (!Number.isInteger(state)) return '';
  switch (state) {
    case 0:
      return <i style={{ color: 'red' }}>Chưa chuyển báo giá</i>;
    case 1:
      return <i style={{ color: 'green' }}>Đã chuyển báo giá</i>;

    default:
      return '';
  }
});
export const RenderComplaintStatus = memo(({ status }) => {
  if (!Number.isInteger(status)) return '';
  switch (status) {
    case 0:
      return <Chip label="Mới" size="small" sx={{ paddingX: '10px' }} />;
    case 1:
      return <Chip label="Chờ duyệt" color="warning" size="small" sx={{ paddingX: '10px' }} />;
    case 2:
      return <Chip label="Đang xử lý" color="primary" size="small" sx={{ paddingX: '10px' }} />;
    case 3:
      return <Chip label="Hoàn thành" color="success" size="small" sx={{ paddingX: '10px' }} />;
    case 4:
      return <Chip label="Hủy" color="error" size="small" sx={{ paddingX: '10px' }} />;
    case 10:
      return <Chip label="Hủy" color="error" size="small" sx={{ paddingX: '10px' }} />;
    default:
      return '';
  }
});

export const RenderTaskJobStatus = memo(({ status }) => {
  if (!Number.isInteger(status)) return '';
  switch (status) {
    case 0:
      return <Chip label="Kế hoạch" size="small" sx={{ paddingX: '10px' }} />;
    case 1:
      return <Chip label="Đang làm" color="warning" size="small" sx={{ paddingX: '10px' }} />;
    case 2:
      return <Chip label="Chờ đánh giá" color="primary" size="small" sx={{ paddingX: '10px' }} />;
    case 3:
      return <Chip label="Hoàn thành" color="success" size="small" sx={{ paddingX: '10px' }} />;
    default:
      return '';
  }
});
